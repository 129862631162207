import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging"
import { reactive } from 'vue';

export let notification = reactive({
    token: "",
    noti: "",
    error: "",
    notificate:null,
})

const firebaseConfig = {
    apiKey: "AIzaSyCt6fY3ou9pCSjug-1QI4Yg4dxCsHyo-3A",
    authDomain: "rakhoi-2023.firebaseapp.com",
    projectId: "rakhoi-2023",
    storageBucket: "rakhoi-2023.appspot.com",
    messagingSenderId: "555029426942",
    appId: "1:555029426942:web:d872cb0e652a998d64abaf",
    measurementId: "G-FMTSDLVJG3"
  };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app)

export default function requestPermission() {
    if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                const app = initializeApp(firebaseConfig)
                const messaging = getMessaging(app)
                getToken(messaging, {
                    vapidKey: 'BC48xs5d0lr3ua8HCVjXT90irtKm5i-_8wrgE7scGu0tgfhWfs73D0m1pPIAWInG4hoyXx-QuXY1PM8qvCNggF8'
                }).then((currentToken) => {
                    if (currentToken) {
                        notification.token = currentToken
                    } else {
                        const notificationOptions = {
                            body: payload.notification.body,
                            icon: '/logo_ico.png'
                        };
                        new Notification('cannot get token', notificationOptions)
                        notification.error = 'cannot get token'
                    }
                }).catch((err) => {
                    if (err) {
                        notification.error = err
                    }
                });
                notification.notificate = messaging
                onMessage(messaging,(payload)=>{
                    notification.noti = payload
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: '/logo_ico.png'
                    };
                    notification.notificate = new Notification(payload.notification.title, notificationOptions)
                    
                })
            }
        })
    }
}
requestPermission()