import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            loggedIn: false, 
            userInfo: JSON.parse(localStorage.getItem('user')),
            information: {},
            paramsSearch: null
        };
    },
    mutations: {
        setApiParams(state, params) {
            state.paramsSearch = params;
            localStorage.setItem('params', JSON.stringify(params));
        },
        login(state, userInfo) {
            state.loggedIn = true;
            state.userInfo = userInfo;
        },
        logout(state) {
            state.loggedIn = false;
            state.userInfo = null;
            localStorage.removeItem('user');
            location.reload();
        },
    },
    actions: {
        loginUser({ commit }, userInfo) {
            commit('login', userInfo);
            let user = {}
            user.accessToken = userInfo.accessToken
            user.refreshToken = userInfo.refreshToken
            localStorage.setItem('user', JSON.stringify(user));
        },
        logoutUser({ commit }) {
            commit('logout');
        },
    },
    getters: {
        isLoggedIn: state => {
            // Kiểm tra trạng thái từ Vuex
            if (state.loggedIn) {
                return true;
            }
            // Kiểm tra dữ liệu từ localStorage
            const userInfo = localStorage.getItem('user');
            return !!userInfo;
        },
        getUserInfo: state => state.userInfo,
    }
});

export default store;