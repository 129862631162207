import { createRouter, createWebHistory } from 'vue-router'
import checkRouter from '../stores/routeSitemap'
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      meta:{title: 'Sàn giao dịch bđs hàng đầu Việt Nam',},
      component: () => import('@/pages/web-view/index.vue'),
      children:[
        {
          path:'',
          meta:{title:'Sàn giao dịch bđs hàng đầu Việt Nam',},
          component: () => import('@/pages/web-view/module/home/index.vue')
        },
        {
          path: '/quan-ly-tai-khoan',
          component: () => import('@/pages/web-view/module/account/index.vue'),
          meta:{
            title: 'Quản Lý tài khoản',
            requiresAuth: true,
          },
          children:[
            {
              path:'',
              meta:{title:'Quản Lý tài khoản',},
              component: () => import('@/pages/web-view/module/account/accountManager/accountManager.vue'),
            },
            {
              path: '/xac-minh-tai-khoan',
              meta:{title: 'Xác minh tài khoản',},
              component: () => import('@/pages/web-view/module/account/accountManager/VerifiAccount.vue'),
            },
            {
              path:'lich-su-giao-dich',
              component: () => import('@/pages/web-view/module/account/financeManager/payHistory.vue'),
              meta:{title: "Lịch sử giao dịch",},
            },
            {
              path:'nap-tien',
              component: () => import('@/pages/web-view/module/account/financeManager/recharge.vue'),
              meta:{title: "Nạp tiền vào tài khoản",},
            },
            {
              path:'thong-bao',
              component: () => import('@/pages/web-view/module/account/notificationManager'),
              meta:{title: "Quản lý thông báo",},
            },
            {
              path:'xoa-tai-khoan',
              component: () => import('@/pages/web-view/module/account/deleteAccount.vue'),
              meta:{title: "Xóa tài khoản",},
            },
            {
              path: '/tao-bat-dong-san',
              component: () => import('@/pages/web-view/module/account/newsManager/addNew.vue'),
              meta:{title: "Tạo bất động sản",},
            },
            {
              path: '/sua-bat-dong-san/:id',
              component: () => import('@/pages/web-view/module/account/newsManager/addNew.vue'),
              meta:{title: "Sửa bất động sản",},
            },
            {
              path: '/sua-ban-nhap-bat-dong-san/:id',
              component: () => import('@/pages/web-view/module/account/newsManager/addNew.vue'),
              meta:{title: "Sửa bất động sản",},
            },
            {
              path: '/danh-sach-tin-dang',
              component: () => import('@/pages/web-view/module/account/newsManager/list.vue'),
              meta:{title: "Danh sách tin đăng",},
            },
            {
              path: '/ban-nhap-tin-dang',
              component: () => import('@/pages/web-view/module/account/newsManager/draft.vue'),
              meta:{title: "Bản nháp tin đăng",},
            },
            {
              path: 'lich-su-nhan-thuong',
              component: () => import('@/pages/web-view/module/account/reward_history.vue'),
              meta:{title: "Lịch sử nhận thưởng",},
            },
          ]
        },
        {
          path: '/tin-tuc',
          meta:{title: 'Tin tức',},
          component: () => import('@/pages/web-view/module/news/index.vue'),
        },
        {
          path: '/tin-tuc/:cate_new_slug',
          meta:{title: 'MarketAnalysisNews',},
          component: () => import('@/pages/web-view/module/news/index.vue'),
        },
        {
          path: 'tin-tuc/chi-tiet-tin-tuc/:news_details_slug',
          meta:{title: 'Chi tiết tin tức',},
          component: () => import('@/pages/web-view/module/news/news_detail.vue'),
        },
        {
          path: '/dat-nen-tho-cu',
          meta:{title: "Đất nền, thổ cư",},
          component: () => import('@/pages/web-view/module/categories/index.vue'),
        },
        {
          path: '/bat-dong-san-rao-ban',
          meta:{title: "Bất động sản rao bán",},
          component: () => import('@/pages/web-view/module/categories/index.vue'),
        },
        {
          path: '/bat-dong-san-cho-thue',
          meta:{title: "Bất động sản cho thuê",},
          component: () => import('@/pages/web-view/module/categories/index.vue'),
        },
        {
          path: '/du-an-bat-dong-san',
          meta:{title: "Dự án bất động sản",},
          component: () => import('@/pages/web-view/module/categories/index.vue'),
        },
        {
          path: '/danh-muc/:cate_slug',
          meta:{title: 'Chi tiết danh mục bất động sản',},
          component: () => import('@/pages/web-view/module/categories/category-detail.vue'),
        },
        {
          path: '/bat-dong-san-gan-ban',
          meta:{title: 'Bất động sản gần bạn'},
          component: () => import('@/pages/web-view/module/categories/category-detail.vue'),
        },
        {
          path: '/bat-dong-san-noi-bat',
          meta:{title: 'Bất động sản nổi bật'},
          component: () => import('@/pages/web-view/module/categories/category-detail.vue'),
        },
        {
          path: '/bat-dong-san-danh-cho-ban',
          meta:{title: 'Bất động sản dành cho bạn'},
          component: () => import('@/pages/web-view/module/categories/category-detail.vue'),
        },
        {
          path: '/chi-tiet-bat-dong-san/:slug',
          meta:{title: "Chi tiết bất động sản",},
          component: () => import('@/pages/web-view/module/products/product_detail.vue'),
        },
        {
          path: '/chi-tiet-du-an/:slug',
          meta:{title: "Chi tiết dự án bất động sản",},
          component: () => import('@/pages/web-view/module/products/projectDetail.vue'),
        },
        {
          path: '/ket-qua-tim-kiem',
          meta:{title: "Kết quả tìm kiếm"},
          component: () => import('@/pages/web-view/module/search/index.vue'),
        },
        {
          path: '/ket-qua-tim-kiem/:search_slug',
          meta:{title: "Kết quả tìm kiếm"},
          component: () => import('@/pages/web-view/module/search/index.vue'),
        },
        {
          path: '/:search_slug',
          component: () => import('@/pages/web-view/module/search/index.vue'),
          beforeEnter: (to, from, next) => {
            if (!checkRouter.includes(to.params.search_slug)) {
              next('404-page');
            } else {
              next();
            }
          }
        },
        {
          path: '/chi-tiet-chi-nhanh/:slug',
          meta:{title: "Dự án bất động sản",},
          component: () => import('@/pages/web-view/module/detailAgency/index.vue'),
        },
        {
          path: '/tin-yeu-thich',
          meta:{
            requiresAuth: true,
            title: "Tin yêu thích",
          },
          component: () => import('@/pages/web-view/module/favorite/index.vue'),
        },
        {
          path: '/danh-sach-thong-bao',
          meta:{
            requiresAuth: true,
            title: "Danh sách thông báo",
          },
          component: () => import('@/pages/web-view/module/notification/index.vue'),
        },
        {
          path: '/quy-dinh/:slug',
          meta:{title: "Quy định",},
          component: () => import('@/pages/web-view/module/detailRegulation/index.vue'),
        },
      ]
    },
    {
      path: '/chuong-trinh/:eventId',
      component: () => import('@/pages/web-view/module/event/lucky_wheel.vue'),
      meta:{
        title: 'Sự kiện',
      },
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('@/pages/web-view/404.vue'),
      meta:{title: "404" ,},
    },
    {
      path: '/404-page',
      component: () => import('@/pages/web-view/404.vue'),
      meta:{title: "404" ,},
    },
  ]
})

// Navigation guard
router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('user')
  if (to.matched.some(route => route.meta.requiresAuth) && !isLoggedIn) {
    window.scrollTo(0, 0);
    next('/');
  } else {
    window.scrollTo(0, 0);
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0);
//   next();
// });
export default router
