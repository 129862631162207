import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import common from './stores/common';
import moment from 'moment'
import '../public/bootstrap-5.0.2-dist/css/bootstrap.min.css'
import '../public/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js'
import ElementPlus from 'element-plus'
moment.locale('vi')
import 'element-plus/dist/index.css'
import '@/assets/css/ckeditor.css'
import axios from 'axios';
import store from './stores/stores'
import fireBase from './stores/fireBase';
import mitt from 'mitt';

import { createMetaManager, plugin as vueMetaPlugin } from 'vue-meta'
const app = createApp(App)

window.zaloJSV2 = {
    zalo_h5_event_handler: function (){
        
    }
};
// app.config.globalProperties.$API_URL = "http://128.199.64.96:3000/api"; //Server DEV
// axios.defaults.baseURL = "http://128.199.64.96:3000/api"; //Server DEV
// app.config.globalProperties.$storage = "http://128.199.64.96:3000/storage/"; //Server DEV

app.config.globalProperties.$API_URL = "https://api.rakhoi.vn/api";
axios.defaults.baseURL = "https://api.rakhoi.vn/api";
app.config.globalProperties.$storage = "https://api.rakhoi.vn/storage/";
// app.config.globalProperties.$API_URL = "http://192.168.1.12:3000/api";
// axios.defaults.baseURL = "http://192.168.1.12:3000/api";
// app.config.globalProperties.$storage = "http://192.168.1.12:3000/storage/";

app.use(gtag);
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$notification = fireBase;
app.config.globalProperties.$moment = moment;
app.use(ElementPlus)
app.use(createMetaManager())
app.use(vueMetaPlugin)
app.mixin(common)
app.use(router)
app.use(store)
app.mount('#app')