<template>
  <div>
     <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content}` : `Rakhoi.vn` }}</template>
      </metainfo>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(to, from) {
  //       document.title = to.meta.title || "RaKhoi";
  //     }
  //   },
  // },
  metaInfo() {
    return {
      og: {
        title: this.$route.meta.title || 'Sàn giao dịch bđs hàng đầu Việt Nam',
        image: '/og_image.png',
        url: 'https://rakhoi.vn' + this.$route.path || 'https://rakhoi.vn',
        description: 'Sàn giao dịch bđs hàng đầu Việt Nam'
      },
      title: this.$route.meta.title || 'Sàn giao dịch bđs hàng đầu Việt Nam',
      description: 'Sàn giao dịch bđs hàng đầu Việt Nam',
    }
  },
};
</script>

<style lang="css">
    body::-webkit-scrollbar {
        width: 5px;
        background-color: #fff;
    }
    body::-webkit-scrollbar-thumb {
        background-color: #F58E16;
    }
</style>
